.c-header {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  &--bike {
    border-bottom: 1px solid #ccd5e2;
  }

  &__primary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.8rem 2.5rem;

    @media screen and (max-width: 650px) {
      padding: 1.5rem 2rem;
    }

    .btn {
      height: 4.5rem;
      min-height: 4.5rem;

      @media screen and (max-width: 1023px) {
        text-align: center;
        i {
          margin: 0;
        }
        span {
          display: none;
        }
      }

      i {
        font-size: 1.4rem;
        transform: rotate(180deg);
      }
    }
  }

  &__logo {
    max-width: 25rem;
    max-height: 8rem;

    @media screen and (max-width: 1023px) {
      max-width: 18rem;
      max-height: 7rem;
    }

    @media screen and (max-width: 450px) {
      max-width: 13rem;
      max-height: 6rem;
    }
  }

  &__usp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.8rem;

    @media screen and (max-width: 650px) {
      padding: 1.5rem 2rem;
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        display: flex;
        align-items: center;
        margin: 0 .9rem;

        @media screen and (max-width: 1023px) {
          display: none;

          &:first-of-type {
            display: flex;
          }
        }

        i {
          font-size: 1.3rem;
          margin-right: 1rem;
        }
        span {
          font-size: 1.6rem;
        }
      }
    }
  }
}
