/*------------------------------------*\
  #Form
\*------------------------------------*/

.c-lang-selector {
  display: flex;
  align-items: center;
  position: relative;

  img {
    position: absolute;
    left: 1.3rem;
    height: auto;
    width: 2rem;

    @media screen and (max-width: 520px) {
      left: 1.2rem;
    }
  }
  i {
    position: absolute;
    top: 2rem;
    right: 1.4rem;
    font-size: 0.8rem;
  }
  select {
    padding: .5rem 4.5rem .5rem 4rem;
    -webkit-appearance: none;
    appearance: none;
    -ms-progress-appearance: none;
    -moz-appearance: none;
    border: 1px solid;
    border-radius: 1rem;
    height: 4.5rem;
    font-size: 1.5rem;

    @media screen and (max-width: 1023px) {
      width: 55px;
      max-width: 55px;
      max-height: 4.5rem;
      padding: 0 3.5rem;
      color: transparent !important;
    }

  }
}

.c-customer-info {
  margin-top: 2rem;
}

.c-form {
  &__select {
    display: flex;
    align-items: center;
    position: relative;

    i {
      position: absolute;
      top: 2rem;
      right: 1.4rem;
      font-size: 0.8rem;
    }
    select {
      padding: .5rem 4.5rem .5rem 1.8rem;
      -webkit-appearance: none;
      appearance: none;
      -ms-progress-appearance: none;
      -moz-appearance: none;
      border: 1px solid;
      border-radius: 1rem;
      height: 4.5rem;
      font-size: 1.5rem;
      width: 100%;
    }
  }

  &__row {
    display: flex;
    margin-bottom: 2rem;

    @media screen and (max-width: 750px) {
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    select {
      outline: none;
    }

    input[type=email], input[type=text], textarea {
      padding: .5rem 4.5rem .5rem 1.8rem;
      -webkit-appearance: none;
      appearance: none;
      -ms-progress-appearance: none;
      -moz-appearance: none;
      border-radius: 1rem;
      height: 4.5rem;
      font-size: 1.5rem;
      outline: none;
    }

    textarea {
      resize: vertical;
      min-height: 100px;
      max-height: 200px;
    }

    input[type=radio] {
      width: auto;
    }

    input, select, textarea, .c-form__select {
      width: 100%;
    }

    &--quarter {
      .c-form__group {
        width: 25%;
        margin-right: 2rem;

        @media screen and (max-width: 750px) {
          width: 100%;
          margin-right: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__group {
    width: 100%;
    margin-right: 2rem;

    @media screen and (max-width: 750px) {
      margin-right: 0;
      margin-bottom: 1rem;
    }

    label {
      font-size: 1.6rem;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &--radio {
      display: flex;
      label {
        margin-right: 2rem;
        display: flex;
        align-items: center;

        span {
          font-size: 1.7rem;
          font-weight: 700;
          margin-left: .5rem;
        }
      }
    }

    &--small {
      max-width: 20%;
      min-width: 20%;

      @media screen and (max-width: 750px) {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }

  &__message {
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: .5rem;
    margin-bottom: 1rem;

    &--error {
      background-color: red;

      i {
        &:before {
          color: $color-white;
        }
      }
      span {
        color: $color-white;
      }
    }
    span {
      font-weight: 700;
      font-size: 1.5rem;
    }
    i {
      margin-right: .75rem;
      font-size: 1.5rem;
    }
  }
}
