/*------------------------------------*\
  #COLORS
\*------------------------------------*/

// Colors names

$color-white: #FFFFFF;
$color-black: #000000;

// Box shadow colors

// Element-colors

// $color-selection: $color-main;
// $scrollbar-color: $color-main;


/*------------------------------------*\
  #SHADOWS
\*------------------------------------*/

// Box-shadows

// Text-shadows

/*------------------------------------*\
  #ANIMATION
\*------------------------------------*/

// Transtions
$transition1: 0.28s all;
$transition2: 0.38s all;
$transition3: 0.55s all;

/*------------------------------------*\
  #RESPONSIVE
\*------------------------------------*/

// Device widths
$mobile-width: 520px;
$tablet-width: 768px;
$between-tablet: 900px;
$desktop-width: 1024px;
