/*------------------------------------*\
  #STANDARD BUTTON
\*------------------------------------*/

.btn {
  padding: 1.2rem 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  span {
    font-weight: 700;
    font-size: 1.6rem;
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 1.6rem;
      margin-right: 1.4rem;
    }
  }

  &--rounded {
    border-radius: 1rem;
  }

  &--outline {
    border: 1px solid;
  }

  &--pill {
    border-radius: 100px;
    padding: .5rem 1rem;
    span {
      font-size: 1.4rem !important;
      line-height: 1;
    }
  }
}
