.c-product-display {
  padding-bottom: 10rem;

  .btn {
    display: inline-flex;
    span {
      color: white;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5rem;

    img {
      max-height: 40rem;
      max-width: 40rem;
      margin: 3rem 0;
    }
  }
  &__header {
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      color: white;
      text-align: center;
      margin: 0;
      font-size: 2.5rem;
    }
    small {
      font-size: 1.7rem;
      color: white;
    }
  }

  &__information {
    padding: 3rem 0;
    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }

  &__specs {
    border-top: 1px solid #ccd5e2;
    padding: 3rem 0;
    @media screen and (max-width: 600px) {
      text-align: center;
    }
    .o-container {
      display: flex;
      flex-direction: column;
    }

    .introduction-text{
      margin-bottom: 5rem;
    }

    iframe{
      width: auto;
      height: 50rem;
      margin-bottom: 5rem;
    }

    &__single {
      display: flex;
      @media screen and (max-width: 600px) {
        display: block;
      }
      span {
        font-size: 1.8rem;

        &:nth-of-type(1) {
          width: 20rem;
          font-weight: 700;
          @media screen and (max-width: 600px) {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  &__cta {
    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }
}

